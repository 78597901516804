@mixin theme-font {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #555555;
}
@mixin after-before {
    display: table;
    content: "";
    position: absolute;
    z-index: 2;
}
@mixin section-padding {
    padding: 70px 0;
    @include media-breakpoint-down(sm) {
        padding: 20px 0;
    }
}

@mixin page-header-padding {
    padding: 40px 0;
}

@mixin page-section-padding {
    padding: 40px 0;
}

@mixin anchor-button {
    padding: 16px 54px;
    text-align: center;
    background-color: $base-color-theme;

    color: #ffffff;

    border-radius: 30px;
    z-index: 1;
    display: inline-block;
    overflow: hidden;
}

@mixin transition-all {
    -webkit-transition: all 0.35s ease;
    -moz-transition: all 0.35s ease;
    transition: all 0.35s ease;
}
