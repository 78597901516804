@import "variables";
$navbar-dark-color: #27537c;
$navbar-dark-hover-color: #000;
$container-max-widths: (
  xl: 1270px
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "mixin";

body {
  @include theme-font;
}
input,
button {
  outline: none;
}
.navbar-dark .navbar-nav {
  .nav-link {
    text-transform: uppercase;
    font-weight: bold;
  }
  .show > .nav-link {
    color: #27537c;
  }
}
.stick-header {
  transition: 1s;
}
.darkHeader {
  background: #fff;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  position: fixed;

  box-shadow: 1px 1px 1px 1px #a5a5a554;
}
#slider {
  &.banner-margin {
    margin-top: 81px;
  }
}
#top-bar {
  padding: 5px 0;
  background: #27527c;
  height: 30px;
  color: #fff;
  a {
    color: #fff;
    display: inline-block;
    margin: 3px;
    &:hover {
      color: #f8f8f8;
    }
  }
}
#what-we-offer {
  background: $bg-color-section;
  @include section-padding;
  padding-top: 0px;
  .owl-offer {
    figure {
      margin: 0 0 0 0;
    }
  }
}
.block-title-content {
  margin-bottom: 45px;
  h2 {
    font-weight: 600;
    color: $base-color-theme;
    font-size: 34px;
  }
  h5 {
    font-weight: 300;
    color: #777777;
    font-size: 17px;
  }
}
#why-us {
  @include section-padding;
  img {
    margin-bottom: 20px;
    margin-top: 40px;
  }
}
#testimonial {
  padding: 70px 0 0px 0;
  color: #fff;
  .testimonial-cara {
    list-style: none;
    padding: 0;
  }
  h2 {
    font-weight: 600;
    color: #fff;
    font-size: 34px;
  }
}

#we-are-here {
  @include section-padding;
  color: #fff;
  h5 {
    font-weight: bold;
    padding: 0 20px;
  }
  #counter {
    span {
      margin-top: 30px;
      display: inline-block;
      font-weight: 600;
      color: $base-color-theme;
      font-size: 34px;
    }
    h6 {
      font-weight: 400;
      color: #b0b0b0;
      font-size: 17px;
    }
  }
}
#partner {
  background: $bg-color-section;
  @include section-padding;
  .partner-slider {
    .partner-logo {
      padding: 15px 40px;

      border-radius: 100px;
    }
    .owl-dots {
      position: relative;
      text-align: center;
      padding-top: 30px;
      button {
        display: inline-block;
        border: 1px solid transparent;
        border-radius: 50%;
        padding: 0;
        margin-right: -1px;
        outline: none;
        &.active {
          border-color: #b0b0b0;
        }

        span {
          display: block;
          width: 6px;
          height: 6px;
          margin: 4px;
          border-radius: 50%;
          background-color: #b0b0b0;
        }
      }
    }
  }
}

#project {
  @include section-padding;
  .block-title-content {
    h2,
    h5 {
      color: #fff;
    }
  }
  .showcas-wrap {
    background: #fff;
    padding: 4px;
    position: relative;
    margin-bottom: 20px;
    figure {
      cursor: pointer;
      position: relative;
      margin-bottom: 0;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;

        top: 0;
        left: 0;
      }
      figcaption {
        position: absolute;
        z-index: 2;
        padding: 20px;
        background: #fff;
        opacity: 0.8;
        width: 100%;
        top: 60%;
        font-weight: bold;
        margin: 0 auto;
        left: 0;
        right: 0;
        color: #27527c;
        text-align: center;
        font-size: 17px;
        text-transform: uppercase;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
      }
      &:hover {
        figcaption {
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }
  }
}

#downalod-brochure {
  @include section-padding;
  .download-wrap {
    padding: 70px 0;
    .block-title-content {
      margin-bottom: 0;
      h2,
      h5 {
        color: #fff;
      }
    }
    .download-form {
      position: relative;
      input[type="email"] {
        width: 100%;
        border-right: none;
        height: 53px;
        border-radius: 30px;
        padding-left: 25px;
        padding-right: 140px;
        border: none;
      }
      button {
        padding: 0 30px;
        text-align: center;
        background-color: $base-color-theme;
        border-color: #333333;
        color: #ffffff;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 30px;
        z-index: 1;
        display: inline-block;
        overflow: hidden;
        border: 1px solid;
      }
    }
  }
}

#map {
  iframe {
    margin-bottom: -10px;
  }
}
footer {
  @include section-padding;
  background: #f8f8f8;
  .footer1 {
    img {
      margin-bottom: 15px;
    }
  }
  .block-title-content {
    margin-bottom: 10px;
    .block-title {
      font-size: 23px;
      font-weight: 600;
    }
  }
  ul.quick-link {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 7px;
      a {
        color: $base-color-text;
        &:hover {
          color: $base-color-theme;
        }
      }
    }
  }
  .footer3 {
    p {
      padding-left: 20px;
      position: relative;
    }
    p.loaction {
      &::before {
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f3c5";
        left: 0;
        color: $base-color-theme;
      }
    }
    p.phone {
      &::before {
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f2a0";
        left: 0;
        color: $base-color-theme;
      }
    }
    p.email {
      &::before {
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f1fa";
        left: 0;
        color: $base-color-theme;
      }
    }
  }
  .footer4 {
    ul.social {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        a {
          width: 40px;
          height: 40px;
          display: inline-block;
          text-align: center;
          color: #fff;
          background: $base-color-theme;
          font-size: 20px;
          line-height: 40px;
          border-radius: 30px;
          border: 1px solid #fff;
          &:hover {
            background: #336388;
          }
        }
      }
    }
  }
  .footer-bottom {
    background-color: #f5f5f5;

    border-top: 1px solid #eeeeee;
    font-size: 13px;

    .copyright {
      padding: 30px 0;
    }
  }
  padding-bottom: 0;
}

.caption-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  .caption-text {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;

    .caption-align {
      display: table-cell;
      vertical-align: middle;
      .caption-bg {
        padding: 0px 10px 0px 0;
        color: #fff;
        h1 {
          max-width: 700px;
          font-size: 55px;
          display: inline-block;
          line-height: 40px;
          strong {
            color: #000;
          }
        }
        h2 {
          font-weight: bold;
          font-size: 45px;
          padding: 15px 0;
        }
        h4 {
          font-weight: 100;
        }
      }
    }
  }
}

#page-content .services-sidebar-o {
  padding: 0;
  top: 81px;
  font-size: 14px;
  a {
    padding: 0.5rem;
  }
  .list-group-item {
    padding: 0;
  }
  .nav-link.disabled,
  a.active {
    background-color: #27527c;
    color: white;
  }
}

#page-header,
#page-header-consistent {
  @include page-header-padding;
  h3 {
    font-weight: 600;
    color: #fff;
  }
}
.padd-top {
  padding-top: 60px;
}
#page-content {
  @include section-padding;
  .service-details {
    @include section-padding;
    background: #fbfbfb;
  }
  ul {
    padding: 0 0 0 18px;
    font-size: 16px;
    margin: 0;
  }
}
.page-heading-border {
  color: $base-color-theme;
  &::after {
    width: 125px;
    border: 1px solid #27527c;
    display: table;
    content: " ";
    margin: 0px auto;
  }
}

#page-contact {
  @include section-padding;
  background: url(../../assets/images/contact-bg.jpg);
  background-size: cover;
  background-position: center center;
  color: #fff;
  h6 {
    text-transform: capitalize;
  }
  a {
    @include anchor-button;
  }
}
.about-offer {
  p {
    font-size: 18px;
    strong {
      color: #27527c;
    }
  }
}

.contact {
  padding: 4%;

  .col-md-9 {
    background: #fff;
    padding: 20px 3%;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .col-md-3 {
    color: #fff;
    background: #346489;
    padding: 20px 3%;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

.contact-info {
  margin-top: 10%;
}
.contact-info img {
  margin-bottom: 15%;
}
.contact-info h2 {
  margin-bottom: 10%;
}

.contact-form label {
  font-weight: 600;
}
.contact-form button {
  background: #25274d;
  color: #fff;
  font-weight: 600;
  width: 25%;
}
.contact-form button:focus {
  box-shadow: none;
}

/* about */
#core-skill {
  @include section-padding;
  background: #fbfbfb;
  img {
    margin: 10px 0 20px 0;
  }
}
#call-us {
  @include section-padding;
  background: $base-color-theme;
  margin-top: 70px;
  color: #fff;
  p {
    font-size: 16px;
  }
}
#we-work {
  @include section-padding;
  p {
    font-size: 18px;
  }
  .page-heading-border {
    display: inline-block;
  }
  .focus-values {
    p {
      padding-left: 15px;
    }
  }
  ul {
    li {
      font-size: 16px;
      list-style: none;
      position: relative;
      margin-bottom: 5px;
      &::before {
        display: table;
        content: "\00BB";
        position: absolute;
        left: -25px;
        top: -13px;
        color: #27527c;
        font-size: 32px;
      }
    }
  }
}
#meet-out-founders {
  p {
    font-size: 18px;
    strong {
      color: #27527c;
    }
  }
}
/* emd about*/

blockquote {
  margin-bottom: 15px;
  padding-left: 25px;
  &::before {
    font-family: "Font Awesome 5 Free";
    content: "\f10d";

    font-size: 20px;
    display: block;

    font-weight: 800;
    color: #27527c;
    text-align: left;
  }
  p {
    font-size: 18px;
  }
}

.acetrum-effect *,
.acetrum-effect:before,
.acetrum-effect:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.acetrum-effect img {
  max-width: 100%;
  backface-visibility: hidden;
  vertical-align: top;
  zoom: 1;
  filter: alpha(opacity=40);
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

.acetrum-effect:before,
.acetrum-effect:after {
  position: absolute;
  top: 20px;
  right: 20px;
  content: "";
  background-color: #fff;
  z-index: 1;
  opacity: 1;
}

.acetrum-effect:before {
  width: 80px;
  height: 1px;
}

.acetrum-effect:after {
  height: 80px;
  width: 1px;
}

.acetrum-effect figcaption {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 15px 20px;
}

.acetrum-effect h3,
.acetrum-effect h4 {
  margin: 0;
  font-size: 1.5em;
  font-weight: normal;
  opacity: 1;
  color: #fff;
  text-transform: capitalize;
}

.acetrum-effect h4 {
  font-size: 0.8em;
  text-transform: uppercase;
}

.acetrum-effect .off-detail {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  .off-table {
    display: table;
    width: 100%;
    height: 100%;
    a {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center;
      color: #fff;
      span {
        border: 1px solid #fff;
        padding: 12px 49px;
        text-transform: capitalize;
        font-size: 14px;
        background: #27527c96;
        display: none;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.acetrum-effect:hover img,
.acetrum-effect.hover img {
  zoom: 0;
  filter: alpha(opacity=100);
  -webkit-opacity: 1;
  opacity: 1;
}

.acetrum-effect:hover:before,
.acetrum-effect.hover:before,
.acetrum-effect:hover:after,
.acetrum-effect.hover:after {
  opacity: 1;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.acetrum-effect:hover:before,
.acetrum-effect.hover:before {
  width: 0;
}

.acetrum-effect:hover:after,
.acetrum-effect.hover:after {
  height: 0;
}

.acetrum-effect:hover h3,
.acetrum-effect.hover h3,
.acetrum-effect:hover h4,
.acetrum-effect.hover h4 {
  opacity: 0;
}
.acetrum-effect .off-detail .off-table a:hover span {
  display: inline-block;
}

.acetrum-effect:hover h3,
.acetrum-effect.hover h3 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.acetrum-effect:hover h4,
.acetrum-effect.hover h4 {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}
.services-sidebar {
  margin-bottom: 20px;
  top: 85px;
  .list-group-item {
    padding: 0.55rem 1rem;
  }

  a {
    color: #555555;

    &.active,
    &:hover {
      border-color: $base-color-theme;
      background-color: $base-color-theme;
      color: white;
    }
  }
}

@media (max-width: 1050px) {
  .what-we-offer-wrap {
    max-width: 768px;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(sm) {
}

.gal-item {
  overflow: hidden;
  padding: 8px;
}
.gal-item .box {
  height: 100%;
  overflow: hidden;
}
.box img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.wwd-image {
  float: left;
  margin: 0 15px 15px 0;
  width: 35%;
  max-width: 400px;
}
.founder-details {
  font-size: 12pt;
  li {
    padding: 5px 0;
  }
}
